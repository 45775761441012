export const BUTTON_TYPES = {
	PRIMARY: "PRIMARY",
	SECONDARY: "SECONDARY",
	TERTIARY: "TERTIARY",
	OUTLINE: "OUTLINE",
	LINK: "LINK",
	PINK: "PINK"
}

export const NAV_ITEM_TYPES = {
	LINK: "LINK",
	BUTTON: "BUTTON",
	DROP_DOWN: "DROP_DOWN",
	LOGO: "LOGO"
}

export const SECTION_TYPES = {
	PAGE: "page",
	GRID: "grid",
	COLUMN: "COLUMN",
	HERO_BANNER: "Hero Banner",
	MEDIA: "media",
	LIST: "List",
	HERO_SECTION: "HERO_SECTION",
	TWO_CALL_TO_ACTION: "TWO_CALL_TO_ACTION",
	WITH_CARDS: "WITH_CARDS",
	MULTIPLE_MEDIA_WITH_CAPTION: "MULTIPLE_MEDIA_WITH_CAPTION",
	SINGLE_MEDIA_WITH_CAPTION_AND_PARAGRAPH: "SINGLE_MEDIA_WITH_CAPTION_AND_PARAGRAPH",
	SINGLE_MEDIA_WITH_PARAGRAPH_AND_LINK: "SINGLE_MEDIA_WITH_PARAGRAPH_AND_LINK",
	HEADING_WITH_SINGLE_MEDIA_AND_BUTTON: "HEADING_WITH_SINGLE_MEDIA_AND_BUTTON",
	REPORTS: "REPORTS",
	CONTACT_BANNER: "CONTACT_BANNER",
	BLOG_POST: "BLOG_POST",
	FEATURE_PRICING_CARD: "FEATURE_PRICING_CARD",
	WITH_ACCORDION: "WITH_ACCORDION",
	STAFF_MEMBER: "STAFF_MEMBER",
	CONTACT_FORM: "CONTACT_FORM",
	STATIC: "STATIC",
	PLUGIN: "PLUGIN",
	COLLATERAL_CARD: "COLLATERAL_CARD",
	CALENDAR_CARD: "CALENDAR_CARD",
	BLOG_HOME: "BLOG_HOME",
	REQUEST_ACCESS_CODE: "REQUEST_ACCESS_CODE",
	LEGAL_SUPPORT: "LEGAL_SUPPORT",
	BUTTON: "BUTTON",
	BUTTON_IMAGE: "BUTTON_IMAGE",
	PRICING_TABLE: "PRICING_TABLE",
	PRICING_FEATURES_TABLE: "PRICING_FEATURES_TABLE",
	PRICING_FEATURES_TABLE_SME: "PRICING_FEATURES_TABLE_SME",
	RELATED_POST: "RELATED_POST",
	TRAINING_CARD: "TRAINING_CARD",
	TESTIMONY_CARD: "TESTIMONY_CARD",
	TESTIMONY_CARD_WITH_IMAGE: "TESTIMONY_CARD_WITH_IMAGE",
	FAQ_CARD: "FAQ_CARD",
	REDIRECT: "redirect",
	CODE: "CODE",
	PRICING_CARD: "PRICING_CARD",
	UPDATE_PRICING_CARD: "UPDATE_PRICING_CARD",
	MINI_STEP_CARD: "MINI_STEP_CARD",
	EMAIL_GATE: "EMAIL_GATE",
	PAPER_FORM: "PaperForm",
	CAROUSEL: "Carousel",
	PATH: "path",
	SUBSCRIBE: "Subscribe",
	HEADER: "HEADER",
	FOOTER: "FOOTER",
	SEO: "seo",
	HEADING: "heading",
	REPORT: "report",
	FEATURED_BLOG_SECTION: "FEATURED_BLOG_SECTION",
	ANNOUNCEMENT_POPUP: "ANNOUNCEMENT_POPUP",
	WEBINAR_POST: "WEBINAR_POST",
	WEBINAR_HOME: "WEBINAR_HOME",
	RICH_TEXT: "RICH_TEXT",
	MOBILE_VIDEO_PLAYER: "MOBILE_VIDEO_PLAYER",
	HOW_IT_WORKS_TIMELINE_ELEMENT: "HOW_IT_WORKS_TIMELINE_ELEMENT",
	TIMELINE_DESCRIPTION: "TIMELINE_DESCRIPTION",
	TIMELINE_WORKFLOW_CARD: "TIMELINE_WORKFLOW_CARD",
	CARD_WITH_IMAGE_TITLE_AND_BUTTON: "CARD_WITH_IMAGE_TITLE_AND_BUTTON",
	ORGANISATION_EXPERIENCE_CARD: "ORGANISATION_EXPERIENCE_CARD"
}

export const LAYOUT_TYPES = {
	SPACER: "SPACER"
}

export const CARD_TYPES = {
	FEATURE_CARD: "FEATURE_CARD",
	SUPPORT_CARD: "SUPPORT_CARD"
}

export const FORM_TYPES = {
	EAP_SUPPORT_FORM: "EAP_SUPPORT_FORM"
}
