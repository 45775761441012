import React from "react"
import PropTypes from "prop-types"
import {backgrounds} from "@constants/colors"
import Styled from "styled-components"

const FluidContainer = Styled.div`

@media (max-width: 1200px) {
	background-position : ${props =>
		props.backgroundPositionLg ? `${props.backgroundPositionLg} !important` : `${props.backgroundPosition}`};
  }

  @media (max-width: 998px) {
	background-position : ${props =>
		props.backgroundPositionMd ? `${props.backgroundPositionMd} !important` : `${props.backgroundPosition}`};
	background-size : ${props =>
		props.backgroundSizeMd ? `${props.backgroundSizeMd} !important` : `${props.backgroundSizeMd}`}	
}

  @media (max-width : 768px){
	  background-image: ${props =>
			!props.hideBackgroundImageonSmallScreen ? `${props.backgroundImage} !important` : "none !important"}
  }
  `

export function Container({children, className, ...props}) {
	return (
		<div className={`${className}`} {...props}>
			{children}
		</div>
	)
}

Container.defaultProps = {
	className: "container"
}

export function Row({children, className, ...props}) {
	return (
		<div className={`row ${className}`} {...props}>
			{children}
		</div>
	)
}

Row.defaultProps = {
	className: ""
}

export function Col({children, className, ...props}) {
	return (
		<div className={`col-12 ${className}`} {...props}>
			{children}
		</div>
	)
}

Col.defaultProps = {
	className: ""
}

export function SectionWrapper({children, bgPrimary, containerFluidProps, containerProps}) {
	return (
		<FluidContainer
			className='container-fluid'
			style={bgPrimary ? {backgroundColor: backgrounds.fadedPurple} : {backgroundColor: backgrounds.white}}
			backgroundPositionLg={containerFluidProps ? containerFluidProps.style.backgroundPositionLg : ""}
			backgroundPositionMd={containerFluidProps ? containerFluidProps.style.backgroundPositionMd : ""} //adding this bg images for different screens, check how it works page
			backgroundSizeMd={containerFluidProps ? containerFluidProps.style.backgroundSizeMd : ""}
			backgroundImage={containerFluidProps ? containerFluidProps.style.backgroundImage : ""}
			{...containerFluidProps}
			hideBackgroundImageonSmallScreen={
				containerFluidProps ? containerFluidProps.style.hideBackgroundImageonSmallScreen : ""
			}>
			<Container {...containerProps}>{children}</Container>
		</FluidContainer>
	)
}

SectionWrapper.propTypes = {
	children: PropTypes.node.isRequired,
	// className: PropTypes.string,
	containerFluidProps: PropTypes.object,
	containerProps: PropTypes.object
}
