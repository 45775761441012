import React from "react"
import {navigate, useStaticQuery, graphql} from "gatsby"
import {BUTTON_TYPES, NAV_ITEM_TYPES} from "@constants"
import {PrimaryButton, TertiaryButton, LinkButton, OutLineButton, PinkButton} from "@styles/buttons"

export function useContainerWidth(selector = "body", padding = 30) {
	const [containerWidth, setContainerWidth] = React.useState(0)

	React.useEffect(() => {
		const el = document.querySelector(selector)
		if (el) {
			const {width} = el.getBoundingClientRect()
			setContainerWidth(width - padding)
		}
	}, [selector, padding])
	return containerWidth
}

export function getDimensions(url = "") {
	const tokens = url.split("/")
	const resolution = tokens.find(token => token.includes("x"))
	if (resolution) {
		return resolution.split("x")
	}
	return [150, 150]
}

export function getButton(type) {
	switch (type) {
		case BUTTON_TYPES.LINK:
			return LinkButton
		case BUTTON_TYPES.PRIMARY:
			return PrimaryButton
		case BUTTON_TYPES.TERTIARY:
			return TertiaryButton
		case BUTTON_TYPES.OUTLINE:
			return OutLineButton
		case BUTTON_TYPES.PINK:
			return PinkButton
		default:
			return props => <PrimaryButton {...props} secondary />
	}
}

export function getSlugFromTitle(title) {
	return title
		.toLowerCase()
		.replace(/[^\w ]+/g, "")
		.replace(/ +/g, "-")
}

export function isExternal(url) {
	return url.includes("http")
}

export function navigateTo(link = "/", {fn} = {}) {
	if (link.includes("calendly.com/getuprise")) {
		const id = `uprise-calendly-popup-widget`
		const calendarContainer = document.getElementById(id)
		if (calendarContainer && !calendarContainer.innerHTML) {
			// eslint-disable-next-line
			window.Calendly.initPopupWidget({
				url: `${link}?hide_event_type_details=1&&primary_color=7d60ff&&text_color=20272c`,
				parentElement: calendarContainer,
				prefill: {},
				utm: {}
			})
		}
	} else if (isExternal(link)) {
		window.open(link, "_blank")
	} else if (link.includes("video") && fn) {
		fn()
	} else {
		navigate(link)
	}
}

export function useMobile() {
	const [isMobile, toggleIsMobile] = React.useState(false)

	React.useEffect(() => {
		toggleIsMobile(window.innerWidth < 1024)
	}, [])

	return isMobile
}

export function getDateWithoutTime(date) {
	let dateWithoutTime = date
	if (typeof date === "string" && date.split(" ").length === 2) {
		dateWithoutTime = date.split(" ")[0]
	}
	return dateWithoutTime
}

export const VideoPlayerContext = React.createContext({playVideo: false})

export const PricingContext = React.createContext({
	pricing: {capped: 0, flexible: 0}
})

export function logger(message) {
	var css = `color: #27ae60; font-size: 18px;`
	console.log("%c %s", css, message)
}

export function useScript(source, url, options = {}) {
	const {shouldLoad, onLoad, onError, async, defer} = options
	const [isScriptLoaded, setIsScriptLoaded] = React.useState(false)

	React.useEffect(() => {
		if (!isScriptLoaded && shouldLoad) {
			setIsScriptLoaded(true)

			const script = document.createElement("script")
			document.body.appendChild(script)
			script.onload = () => {
				logger("Script loaded successfully!")
				onLoad && onLoad()
			}
			script.onerror = () => {
				logger("Error occurred while loading script")
				onError && onError()
			}
			if (async) {
				script.async = true
			}
			if (defer) {
				script.defer = true
			}
			if (source) {
				script.innerHTML = source
			}
			if (url) {
				script.src = url
			}
		}
	}, [isScriptLoaded, source, url, shouldLoad, onLoad, onError, async, defer])
}

export function normalizeImageUrl(imageUrl) {
	if (imageUrl.indexOf("//") === 0) {
		imageUrl = `https:${imageUrl}`
	}
	return imageUrl
}

export function useHeader() {
	const data = useStaticQuery(graphql`
		{
			allStoryBlokEntry: allStoryblokEntry(filter: {full_slug: {eq: "home"}}) {
				edges {
					node {
						id
						name
						full_slug
						published_at(fromNow: true)
						slug
						field_component
						content
					}
				}
			}
		}
	`)
	// Get header
	const {allStoryBlokEntry} = data
	const {node} = allStoryBlokEntry.edges.find(({node}) => node.field_component === "page")
	const content = JSON.parse(node.content)
	const header = content.body.find(item => item.component === "HEADER")
	return header
}
