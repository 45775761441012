// Primary
export const primary = {
	purple: "rgb(125, 96, 255)",
	charcoal: "rgb(47,45,64)"
}

// Secondary
export const secondary = {
	electricPurple: "rgb(145,73,255)",
	electricBlue: "rgb(57,174,255)",
	electricLightOrange: "rgb(255,194,117)",
	electricOrange: "rgb(255,119,34)",
	electricPink: "rgb(255,67,184)",
	electricLightBlue: "rgb(157,180,255)"
}

// Backgrounds
export const backgrounds = {
	fadedPurple: "rgb(248,248,255)",
	white: "rgb(255,255,255)"
}

// Extended Colors
export const extended = {
	charcoal: {
		one: "rgb(109,108,121)", //#6d6c79
		two: "rgb(151,150,160)", // #9796A0
		three: "rgb(193,192,197)",
		four: "rgb(213,213,216)",
		five: "rgb(234,234,236)"
	},
	// Purple
	purple: {
		one: "rgb(150,127,254)",
		two: "rgb(175,158,253)",
		three: "rgb(187,172,252)",
		four: "rgb(212,203,251)", // #d4cbfb
		five: "rgb(237,234,250)" // #edeafa
	},

	// Electric Purple
	electricPurple: {
		one: "rgb(155,91,255)",
		two: "rgb(178,128,255)",
		three: "rgb(199,164,255)",
		four: "rgb(222,200,255)",
		five: "rgb(245,236,255)"
	},

	// blue
	blue: {
		one: "rgb(57,174,255)",
		two: "rgb(97,190,255)",
		three: "rgb(136,206,255)",
		four: "rgb(175,223,255)",
		five: "rgb(215,238,255)"
	},
	// Pink
	pink: {
		one: "rgb(255,67,184)",
		two: "rgb(255,106,198)",
		three: "rgb(255,142,212)",
		four: "rgb(255,180,226)",
		five: "rgb(255,217,241)"
	},
	// Orange
	orange: {
		one: "rgb(255,134,56)",
		two: "rgb(255,159,100)",
		three: "rgb(254,187,145)",
		four: "rgb(255,214,189)",
		five: "rgb(255,228,211)"
	},
	// Light Orange
	lightOrange: {
		one: "rgb(255,206,145)",
		two: "rgb(255,212,158)",
		three: "rgb(255,224,185)",
		four: "rgb(255,237,213)",
		five: "rgb(255,243,227)"
	}
}

// Semantic Colors
export const semantic = {
	error: "rgb(229,33,33)",
	success: "rgb(104, 202, 0)",
	warning: "rgb(254,219,16)"
}
